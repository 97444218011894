@keyframes ldio-aemh6lihbnr {
    0% { transform: translate(-50%,-50%) rotate(0deg); }
    100% { transform: translate(-50%,-50%) rotate(360deg); }
  }
  .ldio-aemh6lihbnr div {
    position: absolute;
    width: 7px;
    height: 7px;
    border: 3px solid #ffffff;
    border-top-color: transparent;
    border-radius: 50%;
  }
  .ldio-aemh6lihbnr div {
    animation: ldio-aemh6lihbnr 1s linear infinite;
    top: 13px;
    left: 10px
  }
  .loadingio-spinner-rolling-ko9vl8eolu {
    width: 20px;
    height: 20px;
    display: inline-block;
    overflow: hidden;
  }
  .ldio-aemh6lihbnr {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-aemh6lihbnr div { box-sizing: content-box; }